<template>
    <div class="education__container">
        <div class="row">
            <div class="education" @click="OpenItems">
                <h1 class="education__title">
                    {{ education.title }}
                    <span class="close-button">
                        <img
                            :class="isOpen ? 'education__arrow' : 'education__arrow--rotate'"
                            src="../../assets/images/chevron-down.svg"
                        />
                    </span>
                </h1>
            </div>
        </div>
        <!-- Content -->
        <transition name="fade">
            <div v-if="isOpen">
                <div class="education__description" v-if="educationKey === 'technical'">
                    По итогам каждого обучения участники проходят итоговое тестирование
                    (тест, зачет, лабораторная работа)
                </div>
                <div
                    class="education__description"
                    v-else-if="
                        educationKey !== 'supervisorNsto' &&
                        educationKey !== 'masterEducation'
                    "
                >
                    Участники проходят входное и итоговое тестирование. По итогам
                    тестирования компании-дилеру присваивается грейд:
                    <div style="margin-top: 10px">
                        <div>1 уровень – начальный</div>
                        <div>2 уровень – базовый</div>
                        <div>3 уровень - продвинутый</div>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="education__item"
                        v-for="(item, id) in education.courses"
                        :key="id"
                    >
                        <template
                            v-if="
                                (item.url === 'test' &&
                                +accountInfo.dealerUniqueId === 789654123) ||
                                item.url !== 'test'
                            "
                        >
                            <h2 class="inner-section-title perm-section-title">
                                <router-link
                                    custom
                                    class="education__item-title"
                                    :to="{
                                        name: 'Education_detail',
                                        params: { id: item.url, isGroup: item.is_group },
                                    }"
                                >
                                    {{ item.title }}
                                </router-link>
                            </h2>
                            <div class="education__level" v-if="item.level">
                                {{ item.level }} уровень
                            </div>
                            <router-link
                                custom
                                :to="{
                                    name: 'Education_detail',
                                    params: { id: item.url, isGroup: item.is_group },
                                }"
                                class="education__item-link"
                            >
                                подробнее &raquo;
                            </router-link>
                            <div class="education__item-time">
                                <img src="../../assets/images/Time_Icon_UIA.svg" />
                                <div class="education__item-time-text">
                                    {{ timeCourse(item) }}
                                </div>
                            </div>
                            <!-- container-center -->
                            <div class="container-center">
                                <router-link
                                    custom
                                    :to="{
                                        name: 'Education_request',
                                        params: { id: item.url, isGroup: item.is_group },
                                    }"
                                    class="education__button-btn"
                                >
                                    ОФОРМИТЬ ЗАЯВКУ
                                </router-link>
                            </div>
                        </template>
                    </div>
                    <!-- container-center -->
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: ["education", "educationKey"],
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        ...mapGetters("account", ["accountInfo"]),
    },
    methods: {
        OpenItems() {
            this.isOpen = !this.isOpen;
        },
        timeCourse(item) {
            if (!Number(item.hours)) {
                return item.hours;
            }

            return item.hours === 16
                ? `${item.hours} часов (2 дня)`
                : item.hours === 48
                    ? `2 дня`
                    : `${item.hours} часа (3 дня)`;
        },
    },
};
</script>

<style scoped lang="sass">

.education__button
    display: block
    text-align: left
    &-btn
        min-width: 100%
        text-align: center
        display: inline-block
        background: #d2233c
        border-radius: 4px
        color: #fff !important
        text-transform: uppercase
        font: 700 16px/24px Futuris
        margin-top: 20px
        padding: 10px
        text-decoration: none
        &:hover
            text-decoration: none


.fade-enter-active, .fade-leave-active
    transition: opacity .3s

.fade-enter, .fade-leave-to
    opacity: 0


.request-form-btn
    width: 100%

.education
    width: 100%
    margin: 0 10px

    &__level
        margin: 10px 0
        font-weight: 400

    &__description
        font-size: 16px
        font-weight: 400
        margin: 15px

    &__container
        margin-top: 40px

        &:nth-child(1)
            margin-top: 60px

    &__title
        font-size: 18px
        padding: 16px
        background-color: #EFF1F6
        font-weight: 700
        cursor: pointer


    &__arrow
        transform: rotate(0deg)
        transition: transform 0.3s ease-in-out


        &--rotate
            transition: transform 0.3s ease-in-out
            transform: rotate(180deg)

    &__item
        box-shadow: 0px 4px 30px -10px rgba(41, 59, 113, 0.2)
        display: flex
        flex-direction: column
        justify-content: space-between
        width: calc(100%/4 - 20px)
        margin: 20px 10px
        padding: 20px 16px
        transition: box-shadow 0.3s ease-in-out

        @media screen and (max-width: 1000px)
            width: calc(100%/2 - 40px)
        @media screen and (max-width: 768px)
            width: calc(100% - 40px)

        &:hover
            box-shadow: 0px 4px 20px -4px rgba(48, 54, 71, 0.45)

        &-title
            font-size: 16px
            line-height: 17px
            font-weight: 700
            text-decoration: none
            transition: color 0.3s ease-in-out
            text-transform: initial
            &:hover
                color: #D2233C

        &-link
            font-weight: 400
            font-size: 16px
            line-height: 17px
            color: #D2233C
            text-decoration: none
            transition: color 0.3s ease-in-out

            &:hover
                color: #212529


        &-time
            font-size: 14px
            line-height: 15px
            font-weight: 400
            margin: 24px 0 0 0
            display: flex
            align-items: center

            &-text
                margin-left: 9px
                font-size: 14px
                line-height: 15px
                font-weight: 400

.visit-page .perm-section-title
    margin-bottom: 2rem
    height: 100%
</style>
