var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"education__container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"education",on:{"click":_vm.OpenItems}},[_c('h1',{staticClass:"education__title"},[_vm._v(" "+_vm._s(_vm.education.title)+" "),_c('span',{staticClass:"close-button"},[_c('img',{class:_vm.isOpen ? 'education__arrow' : 'education__arrow--rotate',attrs:{"src":require("../../assets/images/chevron-down.svg")}})])])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isOpen)?_c('div',[(_vm.educationKey === 'technical')?_c('div',{staticClass:"education__description"},[_vm._v(" По итогам каждого обучения участники проходят итоговое тестирование (тест, зачет, лабораторная работа) ")]):(
                    _vm.educationKey !== 'supervisorNsto' &&
                    _vm.educationKey !== 'masterEducation'
                )?_c('div',{staticClass:"education__description"},[_vm._v(" Участники проходят входное и итоговое тестирование. По итогам тестирования компании-дилеру присваивается грейд: "),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('div',[_vm._v("1 уровень – начальный")]),_c('div',[_vm._v("2 уровень – базовый")]),_c('div',[_vm._v("3 уровень - продвинутый")])])]):_vm._e(),_c('div',{staticClass:"row"},_vm._l((_vm.education.courses),function(item,id){return _c('div',{key:id,staticClass:"education__item"},[(
                            (item.url === 'test' &&
                            +_vm.accountInfo.dealerUniqueId === 789654123) ||
                            item.url !== 'test'
                        )?[_c('h2',{staticClass:"inner-section-title perm-section-title"},[_c('router-link',{staticClass:"education__item-title",attrs:{"custom":"","to":{
                                    name: 'Education_detail',
                                    params: { id: item.url, isGroup: item.is_group },
                                }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1),(item.level)?_c('div',{staticClass:"education__level"},[_vm._v(" "+_vm._s(item.level)+" уровень ")]):_vm._e(),_c('router-link',{staticClass:"education__item-link",attrs:{"custom":"","to":{
                                name: 'Education_detail',
                                params: { id: item.url, isGroup: item.is_group },
                            }}},[_vm._v(" подробнее » ")]),_c('div',{staticClass:"education__item-time"},[_c('img',{attrs:{"src":require("../../assets/images/Time_Icon_UIA.svg")}}),_c('div',{staticClass:"education__item-time-text"},[_vm._v(" "+_vm._s(_vm.timeCourse(item))+" ")])]),_c('div',{staticClass:"container-center"},[_c('router-link',{staticClass:"education__button-btn",attrs:{"custom":"","to":{
                                    name: 'Education_request',
                                    params: { id: item.url, isGroup: item.is_group },
                                }}},[_vm._v(" ОФОРМИТЬ ЗАЯВКУ ")])],1)]:_vm._e()],2)}),0)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }